import 'owl.carousel.es6';

import './Slider.scss';

const Slider = (() => {
    // const slider = document.getElementsByClassName('fullScreenSlider')[0];
    const sliderInfo = document.getElementById('js-slider-info');
    let dots = null;
    const autoplay = 5000;

    const prev = "<i class='fa fa-angle-left'></i>";
    const next = "<i class='fa fa-angle-right'></i>";

    const changeSlider = event => {
        let w = 0;
        sliderInfo &&
            (sliderInfo.innerText = `${event.page.index + 1} / ${
                event.page.count
            }`);

        let progressbar = document.querySelector('#active-progress');

        if (progressbar) {
            progressbar.remove();
        }

        if (dots) {
            //const slider = document.getElementsByClassName('slider')[0];
            const activeDot = dots.getElementsByClassName('active')[0];
            // console.log(activeDot);
            const progress = document.createElement('div');
            progress.setAttribute('id', 'active-progress');
            activeDot.appendChild(progress);
            progressbar = document.querySelector('#active-progress');

            const styles = window.getComputedStyle(progressbar);

            const incr = () => {
                if (w >= 100) {
                    clearInterval(interval);
                } else {
                    w += 25;
                }
                progressbar.style.width = `${w}%`;
            };

            const interval = setInterval(incr, 1000);
        }
    };

    $(document).ready(function () {
        const setDots = event => {
            dots = document.getElementsByClassName('owl-dots')[0];
            changeSlider(event);
        };

        const setCustomNav = () => {
            const contentWrapperWidth = document.getElementsByClassName('js-content')[0].offsetWidth;
            const customOwlNav = document.getElementById('carousel-custom-nav');
            const customOwlDots = document.getElementById('carousel-custom-dots');

            if (contentWrapperWidth && customOwlNav) {
                customOwlNav.style.width = contentWrapperWidth + 'px';
                customOwlDots.style.left = (contentWrapperWidth / 2 ) + 'px';
            }
        };

        const carousel = $('.fullScreenSlider').owlCarousel({
            loop: true,
            margin: 0,
            items: 1,
            // animateIn: 'fadeIn',
            // animateOut: 'fadeIn',
            smartSpeed: 750,
            autoplayTimeout: autoplay,
            mouseDrag: true,
            // autoplayHoverPause: true,
            autoplay: true,
            dotsContainer: '#carousel-custom-dots',
            navContainer: '#carousel-custom-nav',
            responsiveClass: true,
            navText: [prev, next],
            onChanged: event => changeSlider(event),
            onInitialized: event => {
                setCustomNav();
                setDots(event);
            },
            dots: true,
            nav: true,
        });
    });
})();

export default Slider;
