import './Footer.scss';
import LogoSlider from '../LogoSlider/LogoSlider';

const Footer = () => {
	LogoSlider();
	
	const initMap = () => {
		const mapElement = document.getElementById('google-map');
		
		if (mapElement) {
			const latMap = mapElement.dataset.lat;
			const lngMap = mapElement.dataset.lng;
			const center = { lat: parseFloat(latMap), lng: parseFloat(lngMap) };
			const map = new google.maps.Map(
				document.getElementById('google-map'),
				{
					center,
					zoom: 17,
				}
			);
			
			new google.maps.Marker({
				position: center,
				map,
				title: 'Biostudio',
				icon: '/images/icons/marker.svg',
				anchor: new google.maps.Point(142, 157),
			});
		}
	};
	
	initMap();
};

export default Footer;
