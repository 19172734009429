import './ContactForm.scss';

export default (() => {
    const writeUs = [...document.getElementsByClassName('js-write-us')];
    const form = document.getElementById('js-form');
    const closers = [...document.getElementsByClassName('js-close-form')];

    const openForm = () => form.classList.add('active');
    const closeForm = () => form.classList.remove('active');

    writeUs.map(writeBtn => writeBtn.addEventListener('click', openForm));
    closers.map(closer => closer.addEventListener('click', closeForm));
})();
