import './TopMenu.scss';
import { getWindowWidth } from '../../../resources/js/Helpers/Helpers';
import SearchForm from '../../SearchForm/SearchForm';

const topMenu = () => {
	const handleMenuOpen = () => {
		const menu = document.getElementById('js-top-menu');
		const togglers = [...document.getElementsByClassName('js-toggle-menu')];
		
		togglers.map(toggle =>
			toggle.addEventListener('click', () =>
				menu.classList.toggle('active')
			)
		);
	};
	
	const handleMobileMenu = () => {
		if (getWindowWidth() < 1200) {
			const menuItems = [
				...document.getElementsByClassName('has-submenu'),
			];
			
			const toggleSubmenu = e => {
				e.currentTarget.classList.toggle('active');
				const submenu = e.currentTarget.getElementsByClassName('top-menu__submenu')[0];
				submenu.classList.toggle('active');
			};
			
			menuItems.map(menuItem => menuItem.addEventListener('click', toggleSubmenu));
		}
	};
	
	handleMenuOpen();
	handleMobileMenu();
};

export default topMenu;
