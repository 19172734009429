import AOS from 'aos';
// import 'jquery-colorbox';
import '../resources/scss/styles/main.scss';

import topMenu from '../components/Navs/TopMenu/TopMenu';
import Footer from '../components/Footer/Footer';
import Icon from '../components/Icon/Icon';
import ContactForm from '../components/ContactForm/ContactForm';
import {convertSvgToInline} from "../resources/js/Helpers/Helpers";

const fadeLoader = () =>
    setTimeout(() => {
        document.getElementById('preloader').classList.add('closed');
        AOS.init({
            startEvent: 'DOMContentLoaded',
            debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
            throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
            anchorPlacement: 'top-bottom',
            once: true
        });
    }, 1500);

const Init = module => {
    const init = () => {
        fadeLoader();
        topMenu();
        Footer();
        convertSvgToInline();
        module();
        ContactForm();
    };
    
    window.addEventListener('load', init);
};

export default Init;
