import colorbox from 'jquery-colorbox';

import Init from '../../../extendable/Init';
import './Homepage.scss';
import Slider from '../../../components/Slider/Slider';

const Homepage = () => {
	$('.colorbox').colorbox({
		maxWidth: '95%',
		maxHeight: '95%',
		scrolling: false,
		fixed: true,
		title: '',
	});
};

Init(Homepage);
